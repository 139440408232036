import React, { useEffect, useState } from "react";
import {
  chatExistApi,
  createChatsApi,
  getGameDataApi,
} from "../../network/api/otherDetailsApi";
import { useParams, useNavigate, createSearchParams } from "react-router-dom";
import { Typography, Space, Tag, Image, Avatar, Button } from "antd";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { convert } from "html-to-text";
import { useSelector } from "react-redux";
import { timeConvertToDateTime, timeZone } from "../../utils/utils";
const { Title, Paragraph, Text } = Typography;

const SinglePage = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [chatExist, setChatExist] = useState(false);
  // const role = useSelector((state) => state.authUser.user.role_type);
  const authenticated = useSelector((state) => state.authUser.authenticate);
  const gmIdLogin = useSelector((state) => state?.authUser?.gmInfo?.id);

  const [game, setGame] = useState(null);

  const handleContactGm = async () => {
    try {
      if (authenticated) {
        if (!chatExist) {
          await createChatsApi({
            gm: game?.game_master?.id,
          });
        }
        navigate({
          pathname: "/chats",
          search: createSearchParams({
            gm: game?.game_master?.id,
          }).toString(),
        });
      } else {
        navigate("/signup");
      }
    } catch (error) {
      if (error?.message === "Unauthorized") {
        navigate("/login");
      }
    }
  };

  const isFutureDate = (dateString) => {
    const now = new Date();
    const gameDate = new Date(dateString);
    return gameDate > now;
  };

  useEffect(() => {
    const getGameData = async () => {
      try {
        if (/^\d+$/.test(id)) {
          let data = await getGameDataApi({ id });
          if (data?.status_code === 200) {
            setGame(data?.data);

            if (authenticated && gmIdLogin !== data?.data?.game_master?.id) {
              let chatStaus = await chatExistApi({
                gmId: data?.data?.game_master?.id,
              });
              if (chatStaus?.data?.status_code === 200) {
                setChatExist(chatStaus?.data?.data?.gm ? true : false);
              }
            }
          } else {
            navigate("/games");
          }
        }
      } catch (error) {
        if (error?.message === "Unauthorized") {
          navigate("/login");
        }
      }
    };
    getGameData();
  }, [authenticated, gmIdLogin, id, navigate]);

  return (
    <>
      <div className="singlepage-wrap">
        <div className="single-banner">
          <Title level={1}>{game?.title}</Title>
        </div>
        <div className="container blog-content">
          <div className="content-inr">
            <div className="post-thumbnail">
              <Image
                src={
                  game?.image || "/363a3f73-602e-42a1-bb85-df287f264bad.webp"
                }
                preview={false}
              />
            </div>

            <Title level={3}>Descripción</Title>
            <Paragraph>
              <div dangerouslySetInnerHTML={{ __html: game?.description }} />
            </Paragraph>
            <Title level={3}>¿Qué necesitan saber los jugadores?</Title>
            <Paragraph>
              <div dangerouslySetInnerHTML={{ __html: game?.player_needed }} />
            </Paragraph>
          </div>
          <div className="sider">
            <div className="detail-info">
              <Title level={3}>Información detallada</Title>
              <Space align="bottom" className="price">
                <div>
                  <Title level={5}>Precio por sesión/jugador</Title>
                </div>
                <Text className="price-text">€{game?.cost}</Text>
              </Space>
              <Title className="capign-heading" level={4}>
                Asientos libres
              </Title>
              <Tag className="price-tag seat-tag" color="#212640">
                <Image preview={false} src="/seat.png" />
                <div className="filled-text">
                  <span>Asientos llenos</span>
                  {game?.seats_filled ? game?.seats_filled : 0} de {" "}
                  {game?.seats}
                </div>
              </Tag>
              <Title className="capign-heading" level={4}>
                Fecha y hora de la sesión
              </Title>
              <Title style={{ color: "white", marginTop: 0 }} level={5}>
                {timeConvertToDateTime(game?.date)}
              </Title>
              <Title className="capign-heading" level={4}>
                Información
              </Title>
              <ul className="session-listing">
                <li>Duración: {game?.duration} horas</li>
                <li>Tipo : {game?.type}</li>
                <li>Experiencia requerida : {game?.required_experience}</li>
                <li>Creación de personaje : {game?.character_creation}</li>
                <li>Edad: {game?.age_restricted ? "18+" : "Todas las edades"} </li>
              </ul>
              {game &&
                gmIdLogin !== game?.game_master?.id &&
                game?.seats_filled < game?.seats &&
                isFutureDate(game.date) && (
                  <Button
                    ghost
                    block
                    type="primary"
                    size="large"
                    onClick={() => {
                      navigate(`/game-payment/${id}`);
                    }}
                  >
                    Join Campain
                  </Button>
                )}
              <div></div>
              {game?.seats_filled >= game?.seats && (
                <div>
                  <Tag className="price-tag" color="#5572f6">
                    No quedan sitios
                  </Tag>
                </div>
              )}
              {!isFutureDate(game?.date) && (
                <div style={{ marginTop: "20px" }}>
                  <Tag className="price-tag" color="#5572f6">
                    Sesión finalizada
                  </Tag>
                </div>
              )}
            </div>
            <div className="detail-info gm-card">
              <Space size={16} align="bottom" className="card-header">
                <Avatar size={80} src={game?.game_master?.picture} />
                <div>
                  <Title level={2}>{game?.game_master?.name}</Title>
                  <Text className="text">
                    {game?.game_master?.total_reviews
                      ? `(${game?.game_master?.average_reviews}) ${game?.game_master?.total_reviews} reviews`
                      : "No reviews "}
                  </Text>
                </div>
              </Space>
              <ul className="game-master-list">
                <li>
                  {game?.game_master?.experience_player}{" "}
                  <span> años como jugador</span>
                </li>
                <li>
                  {game?.game_master?.experience_game_master}{" "}
                  <span> años como Game Master</span>
                </li>
                <li>
                  {game?.game_master?.games_hosted} <span> partidas organizadas</span>
                </li>
                <li>
                  {game?.game_master?.discord_tag}
                  <span> Discord Tag</span>
                </li>
              </ul>
              <Paragraph className="text">
                {convert(game?.game_master?.about_me_game_master, {
                  wordwrap: 130,
                }).substring(0, 90) + "..."}
              </Paragraph>
              <Space className="footer">
                <Button
                  type="primary"
                  size="large"
                  onClick={() => {
                    navigate(`/game-master/${game?.game_master?.id}`);
                  }}
                >
                  Ver perfil
                </Button>
                {game && gmIdLogin !== game?.game_master?.id && (
                  <Button
                    ghost
                    type="primary"
                    size="large"
                    onClick={handleContactGm}
                  >
                    Contactar GM
                  </Button>
                )}
              </Space>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SinglePage;
