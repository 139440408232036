import React, { useEffect, useState, useRef } from "react";
import {
  Typography,
  Form,
  Input,
  Avatar,
  Badge,
  Upload,
  Button,
  Image,
  message,
} from "antd";
import "./appChat.scss";
import { FileImageOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

import InfiniteScrollComp from "react-infinite-scroll-component";
import { useNavigate, useSearchParams, Link } from "react-router-dom";

import {
  gameImageApi,
  getRoomChateApi,
  getUserChatsApi,
} from "../../network/api/otherDetailsApi";
import useWebSocket from "react-use-websocket";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import { Modal } from "antd";
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const { Title } = Typography;

const AppChat = () => {
  const socketUrl = process.env.REACT_APP_SOCKET_BASE_URL;
  const didmount = useRef(false);
  const navigate = useNavigate();

  const [timer, setTimer] = useState(null);
  const [paramsUrl] = useSearchParams();
  const [loadingAtt, setLoadingAtt] = useState(false);
  const { currentUserID, accessToken, userPic } = useSelector((state) => ({
    currentUserID: state.authUser?.id,
    accessToken: state?.authUser?.accessToken,
    userPic: state?.authUser?.user?.picture,
  }));

  const [socketUser, setSocketUser] = useState(`${socketUrl}/`);
  const [userChats, setUserChats] = useState([]);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [chatId, setChatId] = useState(null);
  const [roomChat, setroomChats] = useState([]);
  const [roomoffset, setRoomOffset] = useState(0);
  const [roomHasMore, setRoomHasMore] = useState(true);
  const [sendMsg, setSendMsg] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const [toggle, setToggle] = useState(true);

  const timeConvert = (date) => {
    let parsedDate = dayjs(date);
    let formattedTime = parsedDate.format("h:mm A");
    return formattedTime;
  };

  const LoadRoomChat = async (firstIn = false) => {
    try {
      // setLoadingRoom(true);
      let games = await getRoomChateApi({
        id: chatId?.id,
        offset: roomoffset,
        limit: 10,
      });
      if (games?.data?.status_code === 200) {
        if (firstIn === true) {
          setroomChats([...games?.data?.data?.messages?.results]);
        } else if (firstIn === false) {
          setroomChats([...roomChat, ...games?.data?.data?.messages?.results]);
        }
        if (games?.data?.data?.messages?.count > roomoffset + 10) {
          setRoomOffset(roomoffset + 10);
        } else {
          setRoomHasMore(false);
        }
        // setLoadingRoom(false);
        // count = games?.data?.data?.count;
      }
    } catch (error) {
      if (error?.message === "Unauthorized") {
        navigate("/login");
      }
      // setLoadingRoom(false);
    }
  };

  const LoadMoreUsers = async (bySearch = false) => {
    try {
      // setLoading(true);
      let games = await getUserChatsApi({ offset, limit: 10, searchValue });

      if (games?.data?.status_code === 200) {
        if (bySearch === true) {
          setUserChats([...games?.data?.data?.results]);
        } else if (bySearch === false) {
          setUserChats([...userChats, ...games?.data?.data?.results]);
        }
        if (
          !didmount.current &&
          paramsUrl.get("gm") &&
          games?.data?.data?.results?.length > 0
        ) {
          games?.data?.data?.results?.forEach((data) => {
            if (data?.gm === paramsUrl.get("gm")) {
              setChatId({
                id: data?.id,
                name: data?.personal_details?.name,
                picture: data?.personal_details?.picture,
              });
            }
          });
          didmount.current = true;
        }

        if (games?.data?.data?.count > offset + 10) {
          setOffset(offset + 10);
        } else {
          setHasMore(false);
        }

        // setLoading(false);
        // count = games?.data?.data?.count;
      }
    } catch (error) {
      if (error?.message === "Unauthorized") {
        navigate("/login");
      }
      // setLoading(false);
    }
  };

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    try {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      setPreviewImage(file.url || file.preview);
      setPreviewOpen(true);
      setPreviewTitle(
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
      );
    } catch (error) {
      // console.log(error);
    }
  };

  const handleRemove = async (file) => {
    let updateFileList = fileList?.length
      ? fileList?.filter((data) => data?.uid !== file?.uid)
      : [];
    let updatedAttachmentlist = attachments?.length
      ? attachments?.filter((data) => data?.uid !== file?.uid)
      : [];

    setFileList(updateFileList);
    setAttachments(updatedAttachmentlist);

    setLoadingAtt(false);
    return true;
  };

  const handleChange = async ({ fileList: newFileList }) => {
    try {
      let isRemove = !!(fileList?.length > newFileList?.length);
      setLoadingAtt(true);
      if (newFileList?.length > 0 && !isRemove) {
        let newFile = newFileList[newFileList?.length - 1];
        const isJpgOrPng =
          newFile?.type === "image/jpeg" ||
          newFile?.type === "image/png" ||
          newFile?.type === "image/jpg";
        if (!isJpgOrPng) {
          message.error("You can only upload JPG/PNG file!");
          throw new Error("You can only upload JPG/PNG file!");
        }
        const isLt2M = newFile?.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          message.error("Image must smaller than 2MB!");
          throw new Error("Image must smaller than 2MB!");
        }
        let file = newFile?.originFileObj;
        let type = newFile?.type;
        let imagePath;

        let formData = new FormData();
        formData.append("file", file);
        formData.append("content_type", type);
        let image = await gameImageApi(formData);
        if (image?.status === 200) {
          imagePath = image?.data?.data?.file_url;
          setAttachments([
            ...attachments,
            { imageUrl: imagePath, uid: newFile?.uid },
          ]);
          setFileList(newFileList);
        }
      }
      setLoadingAtt(false);
    } catch (e) {
      setLoadingAtt(false);
      if (e?.message === "Unauthorized") {
        navigate("/login");
      }
    }
  };

  const uploadButton = (
    <Button>{loadingAtt ? <LoadingOutlined /> : <PlusOutlined />}</Button>
  );
  const fileButton = (
    <div>{loadingAtt ? <LoadingOutlined /> : <FileImageOutlined />}</div>
  );

  function formatChatDate(inputDate) {
    const messageDate = new Date(inputDate);
    const currentDate = new Date();

    // Function to check if two dates have the same year, month, and day
    function datesAreEqual(date1, date2) {
      return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
      );
    }

    const dayDifference = Math.floor(
      (currentDate - messageDate) / (1000 * 60 * 60 * 24)
    );

    if (dayDifference === 0 && datesAreEqual(messageDate, currentDate)) {
      return "today";
    } else if (dayDifference === 1) {
      return "yesterday";
    } else {
      // Format the date as "YYYY-MM-DD"
      const formattedDate = messageDate.toISOString().split("T")[0];
      return formattedDate;
    }
  }

  function areDatesDifferent(date1, date2) {
    const timestamp1 = dayjs(date1);
    const timestamp2 = dayjs(date2);
    return !timestamp1.isSame(timestamp2, "day");
  }

  const socketee = new useWebSocket(socketUser, {
    onMessage: (e) => {
      const messageData = JSON.parse(e.data);
      setroomChats([messageData, ...roomChat]);
    },
    // shouldReconnect: (closeEvent) => true,
    // reconnectInterval: 5000,
  });

  async function handleSendMessage() {
    await socketee.sendJsonMessage({
      message: sendMsg,
      attachments: attachments?.length
        ? attachments?.map((data) => data?.imageUrl)
        : [],
    });
    setSendMsg("");
    setAttachments([]);
    setFileList([]);
  }

  function handleChatClick(chat) {
    setChatId({
      id: chat?.id,
      name: chat?.personal_details?.name,
      picture: chat?.personal_details?.picture,
    });
    setToggle(false);
  }

  useEffect(() => {
    setSocketUser(
      `${socketUrl}/ws/chat/${chatId?.id}/?access_token=${accessToken}`
    );
    setRoomOffset(0);
    setRoomHasMore(true);
    setSendMsg("");
    setroomChats([]);
    chatId && LoadRoomChat(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId]);

  useEffect(() => {
    setUserChats([]);
    setOffset(0);
    setHasMore(true);
    LoadMoreUsers(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  return (
    <div className="chat-application-wrapper">
      <div className={`sidebar-content ${toggle ? "show" : ""}`}>
        <div className="sidebar-header">
          <Link to="/settings">
            <Image preview={false} width={20} height={20} src={"/arrow.png"} />
          </Link>
          <Title
            style={{ cursor: "pointer" }}
            level={4}
            onClick={() => {
              navigate("/");
            }}
          >
            TuGameMaster
          </Title>
        </div>
        <div className="chat-fixed-search">
          <Badge dot status="success">
            <Avatar size={40} src={userPic ? userPic : "/user_profile.png"} />
          </Badge>
          {/* <Search
              placeholder="input search text"
              onSearch={(aa) => {
                setSearchValue(aa);
              }}
              style={{
                width: 200,
              }}
            /> */}
          <Input
            placeholder="Search"
            onChange={(e) => {
              clearTimeout(timer);
              const newTimer = setTimeout(() => {
                setSearchValue(e.target.value);
              }, 1000);
              setTimer(newTimer);
            }}
          />
        </div>
        <div className="chat-user-list-wrapper">
          <Title className="chat-list-title" level={4}>
            Chats
          </Title>
          <div>
            <InfiniteScrollComp
              dataLength={userChats?.length}
              next={LoadMoreUsers}
              // height={300}
              hasMore={hasMore}
              style={{ height: "calc(100vh - 210px)", paddingBottom: 30 }}
              loader={
                <div className="loader" key={0}>
                  Loadings ...
                </div>
              }
            >
              {userChats?.length > 0 &&
                userChats?.map((chat, index) => {
                  return (
                    <div
                      key={`chat-${chat.id}`}
                      className="list-tem"
                      onClick={() => handleChatClick(chat)}
                    >
                      <span className="avatar">
                        <Badge dot status="success">
                          <Avatar
                            size={40}
                            src={
                              chat?.personal_details?.picture ||
                              "/user_profile.png"
                            }
                          />
                        </Badge>
                        <span className="avatar-status-offline"></span>
                      </span>
                      <div className="chat-info">
                        <h5 className="mb-0">{chat?.personal_details?.name}</h5>
                        {chat?.latest_message?.text && (
                          <p className="card-text text-truncate latest-msg">
                            {chat?.latest_message?.text}
                          </p>
                        )}
                      </div>
                      <div className="chat-meta text-nowrap">
                        <small className="chat-time">
                          {timeConvert(chat?.updated_at)}
                        </small>
                      </div>
                    </div>
                  );
                })}
            </InfiniteScrollComp>
          </div>
        </div>
      </div>

      {chatId && (
        <div className="chat-app-window">
          <div className="chat-navbar">
            <Button
              className="chat-toggle-button"
              type="text"
              onClick={() => {
                setToggle(true);
              }}
            >
              <MenuUnfoldOutlined />
            </Button>
            <Badge dot status="success">
              <Avatar size={36} src={chatId?.image || "/user_profile.png"} />
            </Badge>
            <Title level={5}>{chatId?.name}</Title>
          </div>
          <div
            className="user-chats"
            id="scrollableDiv"
            style={{
              overflow: "auto",
              display: "flex",
              flexDirection: "column-reverse",
            }}
          >
            <InfiniteScrollComp
              dataLength={roomChat?.length}
              next={LoadRoomChat}
              hasMore={roomHasMore}
              inverse={true}
              loader={
                <div className="loader" key={0}>
                  Loading ...
                </div>
              }
              height={800}
              style={{ display: "flex", flexDirection: "column-reverse" }}
              scrollableTarget="scrollableDiv"
            >
              {roomChat?.length > 0 &&
                roomChat?.map((chat, index) => {
                  return (
                    <div key={`roomchat${chat.id}`}>
                      {areDatesDifferent(
                        roomChat[index + 1]?.updated_at,
                        chat?.updated_at
                      ) ? (
                        <Title className="divider-text" level={5}>
                          {formatChatDate(chat?.updated_at)}
                        </Title>
                      ) : (
                        ""
                      )}
                      <div
                        className={`chats ${!(chat?.sender === currentUserID)
                            ? "message-in"
                            : "message-out"
                          }`}
                      >
                        <Avatar
                          size={40}
                          src={chat?.user_picture || "/user_profile.png"}
                        />

                        <div className="chat-content-wrap">
                          {chat?.attachments?.length > 0 &&
                            chat?.attachments?.map((att) => {
                              return (
                                <div
                                  key={`chatAttachment-${chat.id}`}
                                  className={`chats ${!(chat?.sender === currentUserID)
                                      ? "message-in"
                                      : "message-out"
                                    }`}
                                >
                                  <div className="chat-content">
                                    <Image
                                      width={120}
                                      height={100}
                                      preview={false}
                                      src={att?.imageUrl || ""}
                                    />
                                  </div>
                                </div>
                              );
                            })}

                          {chat?.text && (
                            <div className="chat-content">{chat?.text}</div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </InfiniteScrollComp>
          </div>
          {fileList?.length > 0 && (
            <div>
              <Upload
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
                onRemove={handleRemove}
                accept={".png,.jpeg,.jpg"}
                multiple={false}
                beforeUpload={() => {
                  return false;
                }}
              >
                {fileList?.length < 1
                  ? fileButton
                  : fileList.length >= 1
                    ? null
                    : uploadButton}
              </Upload>
              <Modal
                open={previewOpen}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
              >
                <img
                  alt="example"
                  style={{
                    width: "100%",
                  }}
                  src={previewImage}
                />
              </Modal>
            </div>
          )}
          <Form className="chat-app-form">
            <Form.Item className="form-item mb-0">
              <Input
                placeholder="Type your message here."
                size="large"
                value={sendMsg}
                disabled={loadingAtt}
                onChange={(value) => {
                  setSendMsg(value.target.value);
                }}
                addonAfter={
                  fileList?.length >= 0 && (
                    <Upload
                      className="attachment-btn"
                      listType=""
                      onChange={handleChange}
                      fileList={fileList}
                      accept={".png,.jpeg,.jpg"}
                      beforeUpload={() => {
                        return false;
                      }}
                      multiple={false}
                    >
                      {loadingAtt ? <LoadingOutlined /> : <FileImageOutlined />}
                    </Upload>
                  )
                }
              />
            </Form.Item>
            <Button
              type="primary"
              size="large"
              disabled={loadingAtt}
              onClick={() => handleSendMessage()}
            >
              Enviar
            </Button>
          </Form>
        </div>
      )}

      {/* {chatId ? (
        <div className="chat-app-window">
          <div className="chat-navbar">
            <Button
              className="chat-toggle-button"
              type="text"
              onClick={() => {
                setToggle(true);
              }}
            >
              <MenuUnfoldOutlined />
            </Button>
            <Badge dot status="success">
              <Avatar size={36} src={chatId?.image || "/user_profile.png"} />
            </Badge>
            <Title level={5}>{chatId?.name}</Title>
          </div>
          <div
            className="user-chats"
            id="scrollableDiv"
            style={{
              overflow: "auto",
              display: "flex",
              flexDirection: "column-reverse",
            }}
          >
            <InfiniteScrollComp
              dataLength={roomChat?.length}
              next={LoadRoomChat}
              hasMore={roomHasMore}
              inverse={true}
              loader={
                <div className="loader" key={0}>
                  Loading ...
                </div>
              }
              height={800}
              style={{ display: "flex", flexDirection: "column-reverse" }}
              scrollableTarget="scrollableDiv"
            >
              {roomChat?.length > 0 &&
                roomChat?.map((chat, index) => {
                  return (
                    <div key={`roomchat${chat.id}`}>
                      {areDatesDifferent(
                        roomChat[index + 1]?.updated_at,
                        chat?.updated_at
                      ) ? (
                        <Title className="divider-text" level={5}>
                          {formatChatDate(chat?.updated_at)}
                        </Title>
                      ) : (
                        ""
                      )}
                      <div
                        className={`chats ${
                          !(chat?.sender === currentUserID)
                            ? "message-in"
                            : "message-out"
                        }`}
                      >
                        <Avatar
                          size={40}
                          src={chat?.user_picture || "/user_profile.png"}
                        />

                        <div className="chat-content-wrap">
                          {chat?.attachments?.length > 0 &&
                            chat?.attachments?.map((att) => {
                              return (
                                <div
                                  key={`chatAttachment-${chat.id}`}
                                  className={`chats ${
                                    !(chat?.sender === currentUserID)
                                      ? "message-in"
                                      : "message-out"
                                  }`}
                                >
                                  <div className="chat-content">
                                    <Image
                                      width={120}
                                      height={100}
                                      preview={false}
                                      src={att}
                                    />
                                  </div>
                                </div>
                              );
                            })}

                          {chat?.text && (
                            <div className="chat-content">{chat?.text}</div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </InfiniteScrollComp>
          </div>
          {fileList?.length > 0 && (
            <div>
              <Upload
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
                beforeUpload={() => {
                  return false;
                }}
                multiple={false}
              >
                {fileList?.length < 1
                  ? fileButton
                  : fileList.length >= 1
                  ? null
                  : uploadButton}
              </Upload>
              <Modal
                open={previewOpen}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
              >
                <img
                  alt="example"
                  style={{
                    width: "100%",
                  }}
                  src={previewImage}
                />
              </Modal>
            </div>
          )}
          <Form className="chat-app-form">
            <Form.Item className="form-item mb-0">
              <Input
                placeholder="Type your message here."
                size="large"
                value={sendMsg}
                onChange={(value) => {
                  setSendMsg(value.target.value);
                }}
                addonAfter={
                  fileList?.length >= 0 && (
                    <Upload
                      className="attachment-btn"
                      listType=""
                      onChange={handleChange}
                      beforeUpload={() => {
                        return false;
                      }}
                      multiple={false}
                    >
                      {loadingAtt ? <LoadingOutlined /> : <FileImageOutlined />}
                    </Upload>
                  )
                }
              />
            </Form.Item>

            <Button
              type="primary"
              size="large"
              onClick={() => handleSendMessage()}
            >
              Send
            </Button>
          </Form>
        </div>
      ) : (
        <div className="chat-app-window">
          <div className="chat-navbar">
            <Button
              className="chat-toggle-button"
              type="text"
              onClick={() => {
                setToggle(!toggle);
              }}
            >
              <MenuUnfoldOutlined />
            </Button>
            <Title level={5}>{}</Title>
          </div>
          <div
            className="user-chats"
            id="scrollableDiv"
            style={{
              overflow: "auto",
              display: "flex",
              flexDirection: "column-reverse",
            }}
          ></div>
        </div>
      )} */}
    </div>
  );
};

export default AppChat;
