import React, { useEffect, useState } from "react";
import {
  Typography,
  Badge,
  Button,
  Space,
  Modal,
  Spin,
  Table,
  message,
} from "antd";

import { CloseCircleOutlined, UndoOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import MenuSettings from "./settingsMenu";
import {
  cancelIntentApi,
  getAccountCreateLinkApi,
  getMeApi,
  getPaymentExpressApi,
  getPaymentsListApi,
} from "../../network/api/otherDetailsApi";

import { useNavigate } from "react-router-dom";
import { getGmMeApi } from "../../network/api/authApi";

const { Title, Link } = Typography;
const columns = [
  {
    title: "Game Master",
    dataIndex: "gm_email",
    key: "gm_email",
  },
  {
    title: "Game",
    dataIndex: "game_title",
    key: "game_title",
  },

  {
    title: "Client",
    dataIndex: "client",
    key: "client",
  },
  {
    title: "Session Date",
    dataIndex: "session_date",
    key: "session_date",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
  },
];

const PayoutStartOver = () => {
  const expressSubmitterDetail = useSelector(
    (state) => state.authUser?.gmInfo?.express_details_submitted
  );

  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [modelType, setModelType] = useState("");
  const [loading, setLoading] = useState(false);
  const [tableInfo, setTableInfo] = useState([]);
  const [offset, setOffset] = useState(0);
  const [loadOffset, setLoadOffset] = useState(0);
  const [cancelId, setCancelId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [count, setCount] = useState(0);
  const navigate = useNavigate();

  const showModal2 = () => {
    setIsModalOpen2(true);
  };
  const handleOk2 = async () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setLoading(true);
    setIsModalOpen2(false);
    try {
      let data = await getAccountCreateLinkApi();
      if (data?.data?.status_code === 200) {
        data?.data?.data?.url && window.open(data?.data?.data?.url, "_blank");
      } else if (data?.data?.status_code === 201) {
        navigate("/payouts-over");
      }
    } catch (error) {
      if (error?.message === "Unauthorized") {
        navigate("/login");
      }
    }
    setLoading(false);
  };
  const handleCancel2 = () => {
    setIsModalOpen2(false);
  };

  const paymentOnStrip = async () => {
    try {
      let data = await getPaymentExpressApi();
      if (data?.data?.status_code === 200) {
        data?.data?.data?.login_url &&
          window.open(data?.data?.data?.login_url, "_blank");
      }
    } catch (error) {
      if (error?.message === "Unauthorized") {
        navigate("/login");
      }
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async (e) => {
    setLoading(true);
    setIsModalOpen(false);
    try {
      if (cancelId) {
        let data = await cancelIntentApi(cancelId);
        if (data?.data?.status_code === 200) {
          await LoadMoreTableData(loadOffset);
          message.success(data?.data?.message);
        }
      }
      setIsModalOpen(false);
    } catch (error) {
      if (error?.message === "Unauthorized") {
        navigate("/login");
      }
    }
    setLoading(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const cancelPaymentIntent = async (id, type) => {
    setModelType(type);
    showModal();
    setCancelId(id);
  };

  const LoadMoreTableData = async (offsetValue) => {
    try {
      setLoading(true);
      setLoadOffset(offsetValue);
      let data = await getPaymentsListApi({
        offset: offsetValue,
        role: "game_master",
      });

      if (data?.data?.status_code === 200) {
        let tableData;
        tableData =
          data?.data?.data?.results?.length > 0
            ? data?.data?.data?.results?.map((item, index) => {
              return {
                key: index,
                game_title: item?.game?.title && (
                  <Link
                    onClick={() => {
                      navigate(`/game/${item?.game?.id}`);
                    }}
                  >
                    {item?.game?.title}
                  </Link>
                ),
                gm_email: item?.game?.game_master?.user && (
                  <Link
                    onClick={() => {
                      navigate(`/game-master/${item?.game?.game_master?.id}`);
                    }}
                  >
                    {item?.game?.game_master?.user}
                  </Link>
                ),
                client: item?.client || "",
                status: item?.status || "",
                session_date: item?.game?.date || "",
                action:
                  item?.status === "succeeded" ? (
                    <Link
                      onClick={() => {
                        cancelPaymentIntent(item?.id, "refundPayment");
                      }}
                    >
                      <UndoOutlined />
                    </Link>
                  ) : (
                    !(
                      item?.status === "canceled" ||
                      item?.status === "refunded"
                    ) && (
                      <Link
                        style={{}}
                        onClick={() => {
                          cancelPaymentIntent(item?.id, "cancelPayment");
                        }}
                      >
                        <CloseCircleOutlined />
                      </Link>
                    )
                  ),
              };
            })
            : [];
        setCount(data?.data?.data?.count);
        setTableInfo(tableData);
        if (data?.data?.data?.count > offset + 10) {
          setOffset(offset + 10);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error?.message === "Unauthorized") {
        navigate("/login");
      }
    }
  };

  const getMe = async () => {
    try {
      await getMeApi();
      LoadMoreTableData(0);
    } catch (error) {
      if (error?.message === "Unauthorized") {
        navigate("/login");
      }
    }
  };

  const getGmMe = async () => {
    try {
      await getGmMeApi();
    } catch (error) {
      if (error?.message === "Unauthorized") {
        navigate("/login");
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getMe();
        await getGmMe();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <Spin spinning={loading} size="large">
      <div className="profile-settings">
        <MenuSettings
          titleTextInfo={{
            title: "Perfil de Facturación",
            text: "Cuenta de facturación con la plataforma Stripe",
          }}
        />

        <div className="forms-wrapper payouts-wrap">
          {expressSubmitterDetail ? (
            <>
              <div>
                <div>
                  <Button onClick={paymentOnStrip} size="large" type="primary">
                    Ver facturación en Stripe
                  </Button>
                </div>
              </div>

              <div className="forms-wrapper payouts-wrap balance-wrap">
                <Spin spinning={loading}>
                  {tableInfo && count > 0 && (
                    <Table
                      className="balance-table"
                      dataSource={tableInfo || []}
                      columns={columns}
                      pagination={{
                        defaultCurrent: 1,
                        pageSize: 10,
                        total: count,
                        onChange: (data) => {
                          LoadMoreTableData((data - 1) * 10);
                        },
                      }}
                    />
                  )}
                  <Modal
                    title={"Are you sure?"}
                    className="payout-modal"
                    okText={
                      modelType === "refundPayment"
                        ? "Refund Payment"
                        : modelType === "cancelPayment"
                          ? "Cancel Booking"
                          : ""
                    }
                    width={400}
                    open={isModalOpen}
                    onOk={handleOk}
                    onCancel={handleCancel}
                  >
                    {modelType === "refundPayment" ? (
                      <p>
                        Al apretar "Reembolso", entiendo que mi pago será devuelto.
                      </p>
                    ) : modelType === "cancelPayment" ? (
                      <p>
                        Al apretar "Cancelar Reserva", entiendo que mi reserva será cancelada de immediato.
                      </p>
                    ) : (
                      ""
                    )}
                  </Modal>
                </Spin>
              </div>
            </>
          ) : (
            <>
              <Title level={4}>Configurar mi perfil de facturación</Title>
              <ul className="payout-listing">
                <li>
                  <Badge count={1} size="large" color="#faad14" />
                  Verificar identidad con Stripe
                </li>
                <li>
                  {" "}
                  <Badge count={2} color="#faad14" />
                  Añadir cuenta bancaria
                </li>
              </ul>
              <Space className="payout-btn-wrap">
                <Button type="primary" onClick={showModal2}>
                  Identificar con Stripe
                </Button>
                {/* <Button type="link">Start Over</Button> */}
              </Space>
            </>
          )}
        </div>
      </div>
      <Modal
        title="¿Quieres continuar?"
        className="payout-modal"
        okText="Aceptar"
        width={400}
        open={isModalOpen2}
        onOk={handleOk2}
        onCancel={handleCancel2}
      >
        <p>Al hacer click en "Aceptar", entiendo que necesitaré:</p>
        <ul>
          <li>Crear un nuevo perfil de facturación</li>
          <li>Añadir mi cuenta bancaria</li>
        </ul>
        <p>
          Nota: Si tienes cobros pendientes, se seguirán enviando a la cuenta bancaria previamente verificada.
        </p>
      </Modal>
    </Spin>
  );
};

export default PayoutStartOver;
