import { Col, Row, Typography, Divider, Space, Image } from "antd";
import React from "react";

import { Link } from "react-router-dom";

const { Title } = Typography;
export default function Footer() {
  return (
    <div className="default-footer">
      <div className="container">
        <Divider className="seprator" />
        <Row gutter={15}>
          =
          <Col xs={12} sm={8} md={5}>
            <Title level={5}>Buscar</Title>
            <ul>
              <li>
                <Link to="/games">Partidas</Link>
              </li>
              <li>
                <Link to="/game-masters">Game Masters</Link>
              </li>
            </ul>
          </Col>
          <Col xs={12} sm={8} md={4}>
            <Title level={5}>Equipo</Title>
            <ul>
              <li>
                <a href="#About">Nosotros</a>
              </li>
              <li>
                <a href="#Blog">Blog</a>
              </li>
              <li>
                <a href="#Contact Us">Contactar</a>
              </li>
            </ul>
          </Col>
          <Col xs={12} sm={8} md={4}>
            {" "}
            <Title level={5}>Ayuda</Title>
            <ul>
              <li>
                <a href="#Contact">Devoluciones</a>
              </li>
              <li>
                <a href="#Report">Incidencias</a>
              </li>
              <li>
                <a href="#FaQ">FaQ</a>
              </li>
            </ul>
          </Col>
          <Col className="text-left" xs={12} sm={16} md={6}>
            <Title level={5}>Tu cuenta</Title>
            <ul>
              <li>
                <a href="#Support">Empezar</a>
              </li>
            </ul>
            <Title className="follow-us" level={5}>
              Síguenos
            </Title>
            <Space>
              <a href="#twitter">
                <Image
                  width={40}
                  height={40}
                  preview={false}
                  src={"/twitter.png"}
                />
              </a>
              <a href="#facebook">
                <Image
                  width={40}
                  height={40}
                  preview={false}
                  src={"/facebook.png"}
                />
              </a>
              <a href="#instagram">
                <Image
                  width={40}
                  height={40}
                  preview={false}
                  src={"/instagram.png"}
                />
              </a>
              <a href="#linkedin">
                <Image
                  width={40}
                  height={40}
                  preview={false}
                  src={"/linkedin.png"}
                />
              </a>
            </Space>
          </Col>
        </Row>
      </div>
    </div>
  );
}
