import React, { useEffect, useState } from "react";
import { Typography, Space, Button, Row, Col, Modal } from "antd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getGmGamesApi,
  deleteGameApi,
} from "../../network/api/otherDetailsApi";
import MenuSettings from "../Settings/settingsMenu";
import GameComp from "../HomePage/gameComp";
import { getGmMeApi } from "../../network/api/authApi";

const { Title } = Typography;

const MyGame = () => {
  const navigate = useNavigate();
  const [selectedGameId, setSelectedGameId] = useState("");
  const [isModalOpenPayouts, setIsModalOpenPayouts] = useState(false);
  const [isModalOpenDeleteGame, setIsModalDeleteGame] = useState(false);

  const [gmGames, setGmGames] = useState([]);
  const expressSubmitterDetail = useSelector(
    (state) => state.authUser?.gmInfo?.express_details_submitted
  );
  let setGameValue = (data) => {
    setGmGames(data || []);
  };
  const payoutsShowModal = () => {
    setIsModalOpenPayouts(true);
  };
  const handleOkPayouts = async () => {
    navigate("/payouts-over");
    setIsModalOpenPayouts(false);
  };
  const handleCancelPayouts = () => {
    setIsModalOpenPayouts(false);
  };

  const deleteGameShowModal = (id) => {
    setSelectedGameId(id);
    setIsModalDeleteGame(true);
  };

  const handleOkDeleteGame = async () => {
    try {
      let data = await deleteGameApi(selectedGameId);
      if (data?.data?.status_code === 200) {
        let data = await getGmGamesApi();
        if (data && data?.status === 200) {
          setGmGames(data?.data?.data || []);
        }
      }
      setIsModalDeleteGame(false);
    } catch (error) {
      if (error?.message === "Unauthorized") {
        navigate("/login");
      }
    }
  };

  const handleCancelDeleteGame = () => {
    setIsModalDeleteGame(false);
  };

  useEffect(() => {
    const getGmGames = async () => {
      try {
        let data = await getGmGamesApi();
        if (data && data?.status === 200) {
          setGmGames(data?.data?.data || []);
        }
      } catch (error) {
        if (error?.message === "Unauthorized") {
          navigate("/login");
        }
      }
    };

    const getGmMe = async () => {
      try {
        await getGmMeApi();
      } catch (error) {
        if (error?.message === "Unauthorized") {
          navigate("/login");
        }
      }
    };
    getGmGames();
    getGmMe();
  }, []);

  return (
    <div>
      <div className="container my-game-card">
        <MenuSettings titleTextInfo={{ title: "Mis Partidas", text: "" }} />
        <div className="container mx-900">
          <Space align="end" className="my-game-head">
            <Title level={4} className="secondary-heading text-left">
              Todas mis partidas
            </Title>
            <Button
              type="primary"
              size="large"
              onClick={() => {
                if (expressSubmitterDetail) {
                  navigate("/gm-games/create-game");
                } else {
                  payoutsShowModal();
                }
              }}
            >
              Crear nueva partida
            </Button>
          </Space>
          <Row gutter={[16, 16]}>
            {gmGames.length > 0 &&
              gmGames.map((game) => {
                return (
                  <Col xs={24} sm={12} md={12} key={`gameId-${game.id}`}>
                    <GameComp
                      game={game}
                      edit={true}
                      setGmGames={setGameValue}
                      payoutsShowModal={payoutsShowModal}
                      deleteGameShowModal={deleteGameShowModal}
                    />
                  </Col>
                );
              })}
          </Row>
        </div>
      </div>
      <Modal
        title={"Perfil de Facturación"}
        className="payout-modal"
        okText="Ir a facturación"
        width={400}
        open={isModalOpenPayouts}
        onOk={handleOkPayouts}
        onCancel={handleCancelPayouts}
      >
        <p>
          Por favor, antes de crear tu primera partida, introduce tus datos de facturación. Los datos servirán para que los jugadores puedan pagarte por tus sesiones. Los pagos se gestionan a través de la plataforma Stripe.
        </p>
      </Modal>
      <Modal
        title={"¿Quieres borrar la sesión?"}
        className="payout-modal"
        okText="Borrar"
        width={400}
        open={isModalOpenDeleteGame}
        onOk={handleOkDeleteGame}
        onCancel={handleCancelDeleteGame}
      >
        <p>
          Al apretar "Borrar", entiendo que la sesión de juego y todas sus reservas serán canceladas.
        </p>
      </Modal>
    </div>
  );
};

export default MyGame;
