import React, { useEffect, useState } from "react";
import { Typography, Button } from "antd";
import { getBlogsListApi } from "../../network/api/otherDetailsApi";
import BlogComp from "./blogComp";
import { LoadingOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;

const BlogPostPage = () => {
  const [loading, setLoading] = useState(false);
  const [blogList, setBlogList] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [offset, setOffset] = useState(0);

  const ListApiCalls = async () => {
    try {
      setLoading(true);
      let blogs = await getBlogsListApi({ offset });
      if (blogs?.status_code === 200) {
        setBlogList(blogs?.data?.results || []);
        if (blogs?.data?.count > offset + 20) {
          setHasMore(true);
          setOffset(offset + 20);
        } else {
          setHasMore(false);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    ListApiCalls();
  });
  return (
    <>
      <div className="blogpost-wrap">
        <div className="sub-banner">
          <div className="container">
            <Title level={2}>Nuestros blogs</Title>
            <Text className="profile-text">
              Aprende a jugar y organizar partidas de rol online
            </Text>
          </div>
        </div>
        <div className="container">
          <div className="blog-content-wrap">
            {blogList?.length > 0 &&
              blogList?.map((blog) => {
                return (
                  <React.Fragment key={`blog-${blog.id}`}>
                    <BlogComp blog={blog} />
                  </React.Fragment>
                );
              })}
          </div>
          {hasMore && (
            <Button
              className="see-all-btn"
              type="primary"
              size="large"
              style={{
                marginTop: 100,
              }}
              onClick={() => {
                !loading && ListApiCalls();
              }}
            >
              Ver más {loading && <LoadingOutlined />}
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default BlogPostPage;
